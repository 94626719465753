var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentRole === 4 || _vm.currentRole === 5)?_c('div',{staticClass:"mt-5 ml-10 mr-10",attrs:{"id":"no-homework"}},[_c('h2',{staticClass:"mb-3"},[_vm._v("No Homework Report")]),_c('v-col',[_c('v-row',{staticClass:"mb-0 d-flex no-homework-select"},[_c('v-select',{staticClass:"mr-2",class:'input-select',attrs:{"items":_vm.locationPermissions,"item-text":"location","item-value":"locationID","append-icon":"keyboard_arrow_down","outlined":"","dense":"","hide-details":"","placeholder":"Center","label":"Center"},on:{"change":_vm.locationChange},model:{value:(_vm.locationID),callback:function ($$v) {_vm.locationID=$$v},expression:"locationID"}}),_c('div',{staticClass:"datapicker-with-label mr-2",class:'datepicker ',style:({ zIndex: 4 })},[_c('p',[_vm._v("Select date")]),_c('date-picker',{attrs:{"format":'DD/MM/YYYY',"placeholder":"Select date"},on:{"input":_vm.dateChange},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1),_c('h3',{staticClass:"d-flex align-center mt-3",attrs:{"md":"4"}},[_vm._v("Total : "+_vm._s(_vm.filtered.length)+" Students")]),(_vm.isLoading)?[_c('div',{staticClass:"pa-6"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)]:_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('div',[(!_vm.isloading)?_c('v-data-table',{staticClass:"elevation-0 hidden-detail-input",attrs:{"headers":_vm.headers,"items":_vm.filtered,"items-per-page":100000000000000000000000000000,"hide-default-footer":"","hide-default-header":"","fixed-header":"","height":"70vh"},on:{"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header header-nohomework"},[_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.value},[(header.value === 'no')?_c('p',{style:({ width: '20px' })},[_vm._v(_vm._s(header.text))]):(header.value === 'stuCode')?_c('p',{style:({ width: '100px' })},[_vm._v(" "+_vm._s(header.text)+" ")]):_c('p',[_vm._v(_vm._s(header.text))])])}),0),_c('tr',{staticClass:"fixed-row-filter"},_vm._l((props.headers),function(header){return _c('th',{key:header.text,staticClass:"select-book",style:({
                    background: '#fff',
                    width: header.value === 'stuCode' ? '100px' : '',
                  })},[_c('Autocomplete',{attrs:{"keyFilter":header.value,"selectedInit":_vm.filters[header.value],"listItem":_vm.groupColumnValueList(header.value)},on:{"setFilter":_vm.setFilter}})],1)}),0)])]}},{key:"item.no",fn:function(ref){
                  var index = ref.index;
return [_c('td',[_c('p',{style:({ width: '20px' })},[_vm._v(_vm._s(index + 1))])])]}},{key:"item.stuCode",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('p',{staticClass:"text-left w-100 mr-4 link",on:{"click":function($event){return _vm.viewDetailProfile(item.stuUserID)}}},[_vm._v(" "+_vm._s(item.stuCode)+" ")])])]}},{key:"item.stuName",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('p',{staticClass:"text-left w-150 mr-4 link",on:{"click":function($event){return _vm.viewDetailProfile(item.stuUserID)}}},[_vm._v(" "+_vm._s(item.stuName)+" ")])])]}},{key:"item.phone",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('p',{staticClass:"text-left w-150 mr-4"},[_vm._v(_vm._s(item.phone))])])]}},{key:"item.classCode",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('p',{staticClass:"text-center mr-4 w-100 link",style:({ width: '100%' }),on:{"click":function($event){return _vm.viewDetailClass(item.classID)}}},[_vm._v(" "+_vm._s(item.classCode)+" ")])])]}},{key:"item.time",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('p',{staticClass:"text-center mr-4 w-100"},[_vm._v(_vm._s(item.time))])])]}},{key:"item.qcName",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('p',{staticClass:"text-left mr-4 w-150 link",on:{"click":function($event){return _vm.viewDetailProfile(item.qcUserID)}}},[_vm._v(" "+_vm._s(item.qcName)+" ")])])]}},{key:"item.ecName",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('p',{staticClass:"text-left mr-4 w-150 link",on:{"click":function($event){return _vm.viewDetailProfile(item.ecUserID)}}},[_vm._v(" "+_vm._s(item.ecName)+" ")])])]}}],null,true)}):_vm._e()],1)])],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }